import React, { useEffect } from "react";
import { Box, Drawer } from "@mui/material";
import Pages from "../../Pages/Pages";
import SidebarDrawerItems from "./SidebarDrawerItems";
import { logoutUser } from "../../utils/axiosInstance";
import { useTranslation } from "react-i18next";

export function SidebarDrawer({
    drawerWidth,
    container,
    mobileOpen,
    handleDrawerTransitionEnd,
    handleDrawerClose,
    setCurrentComponent,
    setIsClosing,
    setMobileOpen,
    ListIcons,
    ListMenu,
    navAreas, // New prop
    accessibleAreas // New prop
}) {
    const { t } = useTranslation();

    useEffect(() => {
    }, [navAreas, accessibleAreas, ListIcons]);

    const onItemClick = (key) => {
        setIsClosing(true);
        setMobileOpen(false);
        switch (key) {
            case Pages.HOME:
            case Pages.TIMEENTRY:
            case Pages.EVALUATION:
            case Pages.PERSONS:
            case Pages.GROUPS:
            case Pages.APPROVAL:
            case Pages.CONTRACTS:
            case Pages.SETTINGS:
                setCurrentComponent(key);
                break;
            case Pages.LOGOUT:
                const logout = logoutUser();
                logout
                    .then((data) => {
                        console.log(data);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                localStorage.removeItem("user");
                localStorage.removeItem("jwt");
                // eslint-disable-next-line no-restricted-globals
                location?.reload();
                break;
            default:
                console.log("Functionality not yet added for: " + key);
        }
    };
    return (
        <Box component="nav" sx={{
            width: {
                sm: drawerWidth - 10
            },
            flexShrink: {
                sm: 0
            }
        }} aria-label="mailbox folders">

            <Drawer container={container} variant="temporary" open={mobileOpen} onTransitionEnd={handleDrawerTransitionEnd} onClose={handleDrawerClose} ModalProps={{
                keepMounted: true // Better open performance on mobile.

            }} sx={{
                display: {
                    xs: "block",
                    sm: "none"
                },
                // Open on mobile else close.
                "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth
                }
            }} PaperProps={{
                sx: {
                    backgroundColor: "secondary.dark",
                    color: "common.dark"
                }
            }}>
                <SidebarDrawerItems onItemClick={onItemClick} ListIcons={ListIcons} ListMenu={ListMenu} navAreas={navAreas} />
            </Drawer>
            <Drawer variant="permanent" sx={{
                display: {
                    xs: "none",
                    sm: "block"
                },
                // Open on large screen else close
                "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth
                },
                backgroundColor: "secondary.light"
            }} PaperProps={{
                sx: {
                    backgroundColor: "secondary.dark",
                    color: "common.dark"
                }
            }} open>
                <SidebarDrawerItems onItemClick={onItemClick} ListIcons={ListIcons} ListMenu={ListMenu} navAreas={navAreas} /> {/* Pass navAreas */}
            </Drawer>
        </Box>
    )
}

import React from "react";
import { useUser } from "./context/UserProvider.js";
import ErrorBoundary from "./utils/ErrorBoundary.jsx";
import ProtectedRoute from "./utils/ProtectedRoute.js";
import { Routes, Route, Navigate } from "react-router-dom";
import SignIn from "./Pages/SignIn.jsx";
import RootNav from "./Components/Navigation/RootNav.jsx";
import ForgetPassword from "./Pages/Authentication/Components/ForgetPassword.jsx";

const LOGIN_PATH = "Anmelden";
const RESET_PASSWORD_PATH = "resetPassword";

const App = () => {
  const { user } = useUser();

  const dashboard = () => {
    if (user === null || user.role === null) {
      return <SignIn />;
    }
    return <RootNav />;
  };

  return (
    <div className="App">
      <Routes>
        <Route
          path=""
          index
          element={
            <ProtectedRoute isAllowed={!!user} redirectPath={LOGIN_PATH}>
              <ErrorBoundary>
                {dashboard()}
              </ErrorBoundary>
            </ProtectedRoute>
          }
        />
        <Route
          path={LOGIN_PATH}
          element={user ? <Navigate to="/" replace /> : <SignIn />}
        />
        <Route path={RESET_PASSWORD_PATH} element={<ForgetPassword />} />
        <Route path="/*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
}

export default App;

import { Dayjs } from "dayjs";
import { api } from "../../utils/axiosInstance";
import { getAuthHeaders } from "../../utils/axiosInstance";

const FetchTimes = async (param) => {
    const response = await api.get(
        `${process.env.REACT_APP_API_URL}${param}`,
        getAuthHeaders()
    );
    return response.data;
};

const PostTimeData = async (data) => {
    console.log('PostTimeData:', data);
    const response = await api.post(
        `${process.env.REACT_APP_API_URL}/api/me/times`,
        data,
        getAuthHeaders()
    );
    return response.data;
};
const DeleteWorkOrBreakTime = async (data) => {
    const response = await api.delete(
        `${process.env.REACT_APP_API_URL}/api/me/times`,
        {
            ...getAuthHeaders(),
            "data": data
        }
    );
    return response.data;
};

const UpdateWorkOrBreakTime = async (data) => {
    console.log("test");
    const response = await api.put(
        `${process.env.REACT_APP_API_URL}/api/me/times`,
        data,
        getAuthHeaders()

    );

    console.log("response: " + response.data);
    return response.data;
};

export {
    FetchTimes,
    PostTimeData,
    DeleteWorkOrBreakTime,
    UpdateWorkOrBreakTime,
};
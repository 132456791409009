import React, { useEffect, useState } from "react";
import { Button, Box, InputLabel, TextField, Typography, Select, Radio, RadioGroup, FormControlLabel, Divider, MenuItem, IconButton } from "@mui/material";
import { ToggleTheme } from "../../context/ToggleThemeProvider";
import { Tick } from "../../Images/CommonLogos";
import { useTheme } from "../../context/UseThemeProvider";
import CustomDatePicker from "../CustomDatePicker";
import { extractTimeFromTimestamp } from '../../utils/ExtractTime';
import { useTranslation } from "react-i18next";
import { PostContract } from "../../Api/Contract";
import Confirmation from "../../Pages/UserDetail/Component/UserModal/Confirmation"
import dayjs from "dayjs";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './Contract.css';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningIcon from '@mui/icons-material/Warning';
import { getDaysOfWeek } from "../../utils/DateUtils"; // Import the utility function

export default function ContractComponent({ onCancel }) {
  const { t } = useTranslation();
  const daysOfWeek = getDaysOfWeek(t); // Use the utility function
  const [team, setTeam] = useState(0);
  var { currentTheme: theme } = useTheme();
  const [contractIsLimited, setContractIsLimited] = useState(false);
  const [contractNumber, setContractNumber] = useState("");
  const [startDate, setStartDate] = useState(dayjs())
  const [endDate, setEndDate] = useState(dayjs())
  const [disableFields, setDisableFields] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const [workTimeEntries, setWorkTimeEntries] = useState([]);
  const [standbyTimeEntries, setStandbyTimeEntries] = useState([]);

  const [workTimeValidation, setWorkTimeValidation] = useState({ valid: true });
  const [standbyTimeValidation, setStandbyTimeValidation] = useState({ valid: true });

  const resetForm = () => {
    setTeam(0);
    setContractIsLimited(false);
    setContractNumber("");
    setStartDate(dayjs());
    setEndDate(dayjs());
    setWorkTimeEntries([]);
    setStandbyTimeEntries([]);
    setWorkTimeValidation({ valid: true });
    setStandbyTimeValidation({ valid: true });
  };

  const addWorkTimeEntry = () => {
    const newEntry = { day: 1, start: new Date().setHours(8, 0), end: new Date().setHours(17, 0), valid: true };
    const updatedEntries = [...workTimeEntries, newEntry];
    setWorkTimeEntries(validateAllEntries(updatedEntries));
  };

  const addStandbyTimeEntry = () => {
    const newEntry = { day: 1, start: new Date().setHours(8, 0), end: new Date().setHours(17, 0), valid: true };
    const updatedEntries = [...standbyTimeEntries, newEntry];
    setStandbyTimeEntries(validateAllEntries(updatedEntries));
  };

  const handleWorkTimeChange = (index, field, value) => {
    const updatedEntries = [...workTimeEntries];
    updatedEntries[index][field] = dayjs(value);
    setWorkTimeEntries(validateAllEntries(updatedEntries));
  };

  const handleStandbyTimeChange = (index, field, value) => {
    const updatedEntries = [...standbyTimeEntries];
    updatedEntries[index][field] = dayjs(value);
    setStandbyTimeEntries(validateAllEntries(updatedEntries));
  };

  const removeWorkTimeEntry = (index) => {
    const updatedEntries = workTimeEntries.filter((_, i) => i !== index);
    setWorkTimeEntries(validateAllEntries(updatedEntries));
  };

  const removeStandbyTimeEntry = (index) => {
    const updatedEntries = standbyTimeEntries.filter((_, i) => i !== index);
    setStandbyTimeEntries(validateAllEntries(updatedEntries));
  };

  const handleWorkDayChange = (index, value) => {
    const updatedEntries = [...workTimeEntries];
    updatedEntries[index].day = value;
    setWorkTimeEntries(validateAllEntries(updatedEntries));
  };

  const handleStandbyDayChange = (index, value) => {
    const updatedEntries = [...standbyTimeEntries];
    updatedEntries[index].day = value;
    setStandbyTimeEntries(validateAllEntries(updatedEntries));
  };

  var employeeID = localStorage.getItem("awo-employeeId")
  var params = `/api/employees/${employeeID}/contracts`

  const handleChange = (event) => {
    setTeam(event.target.value);
  };

  const handleContractTypeChange = (event) => {
    setContractIsLimited(event.target.value === "true");
  };

  const validateTimeEntries = (entries) => {
    for (let i = 0; i < entries.length; i++) {
      const entry = entries[i];
      if (entry.end <= entry.start) {
        return { valid: false, index: i, field: 'end' };
      }
      for (let j = 0; j < entries.length; j++) {
        if (i !== j) {
          const otherEntry = entries[j];
          if (entry.day === otherEntry.day && (
            (entry.start >= otherEntry.start && entry.start < otherEntry.end) ||
            (entry.end > otherEntry.start && entry.end <= otherEntry.end)
          )) {
            return { valid: false, index: i, field: 'start' };
          }
        }
      }
    }
    return { valid: true };
  };

  const validateAllEntries = (entries) => {
    const seenEntries = [];
    return entries.map((entry, i) => {
      const isValid = seenEntries.every((otherEntry) => {
        return entry.day !== otherEntry.day || (
          entry.end <= otherEntry.start || entry.start >= otherEntry.end
        );
      });
      seenEntries.push(entry);
      return { ...entry, valid: isValid };
    });
  };

  const isAnyEntryInvalid = (entries) => {
    return entries.some(entry => !entry.valid);
  };

  const convertToMinutes = (time) => {
    const hours = time.hour();
    const minutes = time.minute();
    return hours * 60 + minutes;
  };

  var data = {
    "ID": employeeID,
    "ContractNumber": contractNumber,
    "TeamID": "0",
    "Start": startDate?.toISOString()?.split('T')[0],
    "Limited": contractIsLimited,
    "End": contractIsLimited ? endDate?.toISOString()?.split('T')[0] : "",
    "work-times": workTimeEntries.map(entry => ({
      day: entry.day,
      start: convertToMinutes(dayjs(entry.start)),
      end: convertToMinutes(dayjs(entry.end)),
    })),
    "standby-times": standbyTimeEntries.map(entry => ({
      day: entry.day,
      start: convertToMinutes(dayjs(entry.start)),
      end: convertToMinutes(dayjs(entry.end)),
    })),
  }

  const sendData = () => {
    const workTimeValidationResult = validateTimeEntries(workTimeEntries);
    const standbyTimeValidationResult = validateTimeEntries(standbyTimeEntries);

    setWorkTimeValidation(workTimeValidationResult);
    setStandbyTimeValidation(standbyTimeValidationResult);

    if (!contractNumber) {
      alert(t("contract-number-required"));
      return;
    }

    if (!workTimeValidationResult.valid) {
      alert(t("invalid-work-time-entry"));
      return;
    }

    if (!standbyTimeValidationResult.valid) {
      alert(t("invalid-standby-time-entry"));
      return;
    }

    setConfirmationModalOpen(true);
  };

  const confirmSendData = () => {
    setDisableFields(false);
    var response = PostContract(params, data);
    response
      .then((res) => {
        console.log(res);
        onCancel(); // Hide the add contract form
      })
      .catch((error) => {
        console.log(error);
        alert(t("error-submitting-contract") + " " + (error.response?.data || ""));
      });
    setConfirmationModalOpen(false);
  };

  var { ThemeToggle } = ToggleTheme();
  var textColor = ThemeToggle === "dark" ? "white" : "black";
  var radioLabelColor = ThemeToggle === "dark" ? "white" : "";

  return (
    <>
      <Box sx={{ mt: "4px", ml: "10px" }}>
        <Typography variant="h4" gutterBottom>
          {t("add-contract")}
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: { sm: "1fr 1fr" },
            gap: 2,
          }}
        >
          <Box>
            <Box className="flex align-center items-center">
              <InputLabel
                id="contract-number-label"
                sx={{
                  mr: "19px",
                  color: textColor,
                }}
              >
                {t("contract-number")}
              </InputLabel>
              <TextField
                size="small"
                id="contract-number"
                value={contractNumber}
                onChange={(e) => { setContractNumber(e.target.value) }}
                autoComplete="off"
                sx={{ boxShadow: 1, width: "200px" }}
                required
              />
              {!contractNumber && <ErrorOutlineIcon sx={{ color: "red", ml: 1 }} />}
            </Box>
            <Box sx={{ display: "flex", mt: "10px", color: "black" }}>
              <InputLabel
                id="team-label"
                sx={{
                  color: textColor,
                  mt: "8px",
                }}
              >
                {t("team")}
              </InputLabel>
              <Select
                labelId="team-label"
                id="team"
                value={team}
                onChange={handleChange}
                sx={{
                  height: "40px",
                  width: "200px",
                  ml: "100px",
                  boxShadow: 1,
                }}
              >
                {/* Integrate Team Here */}
                <MenuItem value={"0"}>-</MenuItem>
              </Select>
            </Box>
            <Box sx={{ display: "flex", mt: "10px", alignItems: "center" }}>
              <InputLabel
                id="start-contract-label"
                sx={{
                  mr: "29px",
                  color: textColor,
                }}
              >
                {t("start-contract")}
              </InputLabel>
              <CustomDatePicker Date={startDate} />
            </Box>

            <RadioGroup
              aria-labelledby="contract-type-radio-group"
              value={contractIsLimited.toString()}
              name="contract-type-radio-group"
              sx={{
                display: "flex",
                flexDirection: "row",
                mt: "10px",
              }}
              onChange={handleContractTypeChange}
            >
              <FormControlLabel
                value="true"
                control={
                  <Radio
                    style={{
                      color: radioLabelColor,
                    }}
                  />
                }
                label={t("limited")}
              />
              <FormControlLabel
                value="false"
                control={
                  <Radio
                    style={{
                      color: radioLabelColor,
                    }}
                  />
                }
                label={t("unlimited")}
              />
            </RadioGroup>
            {contractIsLimited && (
              <Box sx={{ display: "flex", mt: "10px", alignItems: "center" }}>
                <InputLabel
                  id="end-contract-label"
                  sx={{
                    mr: "115px",
                    color: textColor,
                  }}
                >
                  {t("until")}
                </InputLabel>
                <CustomDatePicker Date={endDate} />
              </Box>
            )}
          </Box>
        </Box>
        <Divider sx={{ mt: "24px", mb: "24px" }} />

        <Box>
          <Typography variant="h5" gutterBottom>
            {t("add-work")}
          </Typography>

          <TransitionGroup>
            {workTimeEntries.map((entry, index) => (
              <CSSTransition key={index} timeout={500} classNames="fade">
                <Box sx={{ display: "flex", mt: "10px", alignItems: "center" }}>
                  <Select
                    id={`work-day-${index}`}
                    value={entry.day}
                    onChange={(e) => handleWorkDayChange(index, e.target.value)}
                    sx={{ width: "150px", mr: "10px" }}
                  >
                    {daysOfWeek.map((day, i) => (
                      <MenuItem key={i} value={i}>
                        {day}
                      </MenuItem>
                    ))}
                  </Select>
                  <TimePicker
                    id={`work-start-${index}`}
                    value={dayjs(entry.start)}
                    onChange={(value) => handleWorkTimeChange(index, "start", value)}
                    maxTime={dayjs(entry.end)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          boxShadow: 1,
                          width: "100px",
                          mr: "10px",
                        }}
                      />
                    )}
                  />
                  <ArrowForwardIcon sx={{ m: "10px" }} />
                  <TimePicker
                    id={`work-end-${index}`}
                    value={dayjs(entry.end)}
                    onChange={(value) => handleWorkTimeChange(index, "end", value)}
                    minTime={dayjs(entry.start)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          boxShadow: 1,
                          width: "100px",
                          mr: "10px",
                        }}
                      />
                    )}
                  />
                  <IconButton onClick={() => removeWorkTimeEntry(index)} aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                  {!entry.valid && <WarningIcon sx={{ color: "red", ml: 1 }} />}
                </Box>
              </CSSTransition>
            ))}
          </TransitionGroup>
          <Button sx={{ mt: "20px" }} variant="contained" onClick={addWorkTimeEntry} color="primary">{t("add-time-entry")}</Button>

        </Box>

        <Divider sx={{ mt: "24px", mb: "24px" }} />

        <Box>
          <Typography variant="h5" gutterBottom sx={{ mt: "40px" }}>
            {t("add-standby")}
          </Typography>

          <TransitionGroup>
            {standbyTimeEntries.map((entry, index) => (
              <CSSTransition key={index} timeout={500} classNames="fade">
                <Box sx={{ display: "flex", mt: "10px", alignItems: "center" }}>
                  <Select
                    id={`standby-day-${index}`}
                    value={entry.day}
                    onChange={(e) => handleStandbyDayChange(index, e.target.value)}
                    sx={{ width: "150px", mr: "10px" }}
                  >
                    {daysOfWeek.map((day, i) => (
                      <MenuItem key={i} value={i}>
                        {day}
                      </MenuItem>
                    ))}
                  </Select>
                  <TimePicker
                    id={`standby-start-${index}`}
                    value={dayjs(entry.start)}
                    onChange={(value) => handleStandbyTimeChange(index, "start", value)}
                    maxTime={dayjs(entry.end)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          boxShadow: 1,
                          width: "100px",
                          mr: "10px",
                        }}
                      />
                    )}
                  />
                  <ArrowForwardIcon sx={{ m: "10px" }} />
                  <TimePicker
                    id={`standby-end-${index}`}
                    value={dayjs(entry.end)}
                    onChange={(value) => handleStandbyTimeChange(index, "end", value)}
                    minTime={dayjs(entry.start)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          boxShadow: 1,
                          width: "100px",
                          mr: "10px",
                        }}
                      />
                    )}
                  />
                  <IconButton onClick={() => removeStandbyTimeEntry(index)} aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                  {!entry.valid && <WarningIcon sx={{ color: "red", ml: 1 }} />}
                </Box>
              </CSSTransition>
            ))}
          </TransitionGroup>
          <Button sx={{ mt: "20px" }} variant="contained" onClick={addStandbyTimeEntry} color="primary">{t("add-time-entry")}</Button>
        </Box>

        <Box sx={{ mt: "20px" }}>
          <Button variant="contained" color="secondary" onClick={() => { resetForm(); onCancel(); }}>
            {t('cancel')}
          </Button>
          <Button variant="contained" color="success" sx={{ ml: "10px" }} onClick={sendData} disabled={!contractNumber || isAnyEntryInvalid(workTimeEntries) || isAnyEntryInvalid(standbyTimeEntries)}>
            {t("submit_contract_title")}
          </Button>
        </Box>
      </Box >

      <Confirmation
        disableFields={disableFields}
        setDisableFields={setDisableFields}
        confirmationModalOpen={confirmationModalOpen}
        setConfirmationModalOpen={setConfirmationModalOpen}
        confirmSendData={confirmSendData}
        title={t("submit_contract_title")}
        content={t("submit_contract_message")}
        confirm={t("confirm")}
        cancel={t("cancel")}
      />
    </>
  );
}

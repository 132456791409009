import React, { useEffect, useState } from "react";
import BoxStyle from "../../../Person/Style/PersonStyle.js";
import { Info } from "../../../../Images/CommonLogos.jsx";
import Confirmation from "./Confirmation.jsx";
import CurrentDay from "./CurrentDay.jsx";
import Detail from "./Detail.jsx";
import CsvDownloader from "../../../../Components/CsvDownloader.jsx";
import { ToggleTheme } from "../../../../context/ToggleThemeProvider.js";
import DownloadIcon from '@mui/icons-material/Download';
import {
  DeleteWorkOrBreakTime,
  UpdateWorkOrBreakTime,
  FetchTimes,
} from "../../../../Api/POST/Times.js";
import Style from "../../../Styles/EvaluationStyles.js";
import { useTranslation } from "react-i18next";
import { ConvertSecondsToTimeFormat } from "../../../../utils/TimeUtils.tsx";  
import { fetchFilteredItems } from "../../../../utils/fetchFilteredItems.js";

function TimesTable(props) {
  const { t } = useTranslation();
  const {
    filteredItems, setFilteredItems, downloadItems, setDownloadItems, currentMonth, currentYear, months, detailTime, setDetailTime,
    setDetailModalOpen, detailModalOpen
  } = props
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [dateList, setDateList] = useState([]);
  const [disableFields, setDisableFields] = useState(true);
  const [modalDate, setModalDate] = useState("");
  const [deleteItems, setDeleteItems] = React.useState([]);
  const [updateItems, setUpdateItems] = React.useState([]);
  const [validUpdate, setValidUpdate] = useState(true);
  var { ThemeToggle } = ToggleTheme();
  const [checkCancel, setCheckCancel] = useState(
    Array.from({ length: 10 }, () => false)
  );

  const CurrentDayProps = {
    modalOpen, modalDate, disableFields, setDisableFields, validUpdate, setConfirmationModalOpen, setModalOpen,
    dateList, UpdateFields, setUpdateItems, checkCancel, setCheckCancel, deleteItems, extractTimeFromTimestamp, setDeleteItems
  };
  const DetailsProps = {
    setDetailModalOpen, detailModalOpen
  };

  const confirmation_title = "update_day_times_confirmation_title";
  const confirmation_content = "update_day_times_confirmation_content";
  const confirmation_confirm_text ="update_day_times_confirmation_confirm_text";
  const confirmation_cancel_text = "update_day_times_confirmation_cancel_text";

  function confirmSendData() { 
    if (deleteItems.length !== 0) {
      console.log("performing delete.");
      deleteItemsAsync();
    }
    if (updateItems.length !== 0) {
      console.log("performing update.");
      updateItemAsync();
    }
  }

  const ConfirmationProps = { 
    confirmationModalOpen, 
    setDisableFields, 
    setConfirmationModalOpen, 
    disableFields, 
    confirmSendData, 
    title: confirmation_title, 
    content: confirmation_content, 
    confirm: confirmation_confirm_text, 
    cancel: confirmation_cancel_text 
  };

  function getScheduleForDate(date) {
    console.log("getting schedule.");
    console.log(date);

    const formattedDate = date.toISOString().split("T")[0];
    const schedule = [];

    if (detailTime?.hasOwnProperty(formattedDate)) {
      const entries = detailTime[formattedDate];
      for (const entry of entries) {
        if (entry.type !== "required-break") {
          const startTime = new Date(entry.start.date);
          const endTime = new Date(startTime.getTime() + entry.duration * 1000);
          schedule.push({
            type: entry.type,
            source_id: entry.source_id,
            start: entry.start.date,
            end: endTime.toISOString(),
            isStart: entry.isStart,
            isEnd: entry.isEnd,
          });
        }else{
          schedule.push({
            type: entry.type,
            duration: entry.duration,
          });
        }
      }
    }
    setDateList(schedule);
  }

  function extractTimeFromTimestamp(timestamp) {
    const tempDate = new Date(timestamp);
    var formattedData = `${tempDate.getFullYear() +
      "-" +
      (tempDate.getMonth() + 1) +
      "-" +
      tempDate.getDate() +
      " " +
      tempDate.getHours().toString().padStart(2, "0") +
      ":" +
      tempDate.getMinutes().toString().padStart(2, "0")
      }`;
    return formattedData;
  }

  function UpdateFields(data) {
    data.start = extractTimeFromTimestamp(data.start);
    data.end = extractTimeFromTimestamp(data.end);
    if (data.start < data.end) {
      setValidUpdate(true);
      setUpdateItems((prev) => {
        const existingIndex = prev.findIndex(
          (item) => item.id === data.source_id
        );
        if (existingIndex !== -1) {
          const updatedItems = [...prev];
          updatedItems[existingIndex] = {
            ...updatedItems[existingIndex],
            "start": data.start,
            "end": data.end,
          };
          return updatedItems;
        } else {
          return [
            ...prev,
            {
              "id": data.source_id,
              "start": data.start,
              "end": data.end,
            },
          ];
        }
      });
    } else {
      setValidUpdate(false);
    }
  }

  const deleteItemsAsync = () => {
    deleteItems.map((id) => {
      var response = DeleteWorkOrBreakTime({ 'id': id })
      response.then((res) => {
        setCheckCancel(Array.from({ length: 10 }, () => false));
        setModalOpen(false);
      }).catch((error) => {
      })
    })
    setDeleteItems([]);
  };

  const updateItemAsync = () => {
    updateItems.map((data) => {
      console.log("updating item.");
      console.log(data);
      var response = UpdateWorkOrBreakTime(data)
      response.then((data) => {
        setModalOpen(false)
      }).catch((error) => {
        alert(error);
      })
    })
    setUpdateItems([]);
  }

  const getItemAsync = () => {
    fetchFilteredItems(currentYear, currentMonth, setFilteredItems, setDownloadItems, setDetailTime);
  };

  useEffect(() => {
    if (modalOpen == false) {
      getItemAsync();
    }
  }, [modalOpen])

  return (
    <>
      <div>
        <button style={{ color: ThemeToggle === "dark" ? "white" : "black", padding: 5, fontWeight: 900 }} onClick={() => {
          CsvDownloader(downloadItems, (months[currentMonth]) + " " + currentYear);
        }}> {t("download")} <DownloadIcon />
        </button>
        <table
          className={"overflow-y-scroll w-[100%] whitespace-nowrap" + (ThemeToggle === "dark" ? " bg-gray" : "")}
        >
          <thead>
            <tr className="w-[100%]">
              <th className={BoxStyle.th + " text-left"}>{t("date")}</th>
              <th className={BoxStyle.th + " text-left"}>{t("total-time")}</th>
              <th className={BoxStyle.th}>{t("details")}</th>
            </tr>
          </thead>
          <tbody>
            {filteredItems?.map((item, index) => (
              <tr
                key={item.id}
                className={index % 2 === 0 ? "bg-narrow " : "bg-textBox"}
              >
                <td className={BoxStyle.td}>
                  {item.date}
                </td>
                <td className={BoxStyle.td}>
                  {item.time}
                </td>
                <td
                  className={BoxStyle.td + " cursor-pointer text-left"}
                  onClick={() => {
                    setDisableFields(true);
                    setModalOpen(true);
                    setModalDate(item.id);
                    setCheckCancel(Array.from({ length: 10 }, () => false));
                    getScheduleForDate(new Date(item.id));
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Info />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {
          filteredItems?.length === 0 && (
            <div className={Style.noRecord}>
              {t("no-recordFound")}
            </div>
          )
        }
      </div >
      <CurrentDay {...CurrentDayProps} />
      <Confirmation {...ConfirmationProps} />
      <Detail {...DetailsProps} />
    </>
  );
}

export default TimesTable;
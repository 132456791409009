import * as React from "react";
import Style from "../Style/UserView";
import AddPerson from "./AddPerson";
import PersonTable from "./PersonTable";
import BoxStyle from "../Style/PersonStyle";
import { useTranslation } from "react-i18next";
import Pages from "../../Pages";
import { GetAllPerson, DeletePerson } from "../../../Api/PersonApi";
import TokenizedSearchBar from "../../../Components/TokenizeSearchBar";
import Confirmation from "../../UserDetail/Component/UserModal/Confirmation";

export default function UserView(props) {
  const { t } = useTranslation();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(true);
  const [open, setOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuData, setMenuData] = React.useState(null);
  var [searchValue, setSearchValue] = React.useState([]);
  const [searchBtn, setSearchBtn] = React.useState(false);
  const [filtername, setFiltername] = React.useState(false);
  const [employeeData, setEmployeeData] = React.useState([]);
  const [filteremail, setFilteremail] = React.useState(false);
  const [originalData, setOriginalData] = React.useState(false);
  const [disableFields, setDisableFields] = React.useState(false);
  const [employeeEditableData, setEmployeeEditableData] = React.useState();
  const { currentComponent, setCurrentComponent, setUserVertragName } = props;
  const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);

  const openmenu = Boolean(anchorEl);

  const filteredItems = employeeData.filter((item) => {
    if (searchValue && searchValue.length > 0) {
      const searchTokens = searchValue?.map(token => token?.toString()?.toLowerCase());

      const matchesAllTokens = searchTokens.every(token =>
        item.name?.toLowerCase().includes(token) ||
        item.email?.toLowerCase().includes(token)
      );

      return matchesAllTokens;
    }
    return true;
  });

  const transformData = (data) => {
    return Object.values(data).map(user => ({
      id: user.employeeID,
      employeeNummer: user.employeeNumber,
      vorname: user.firstName,
      nachname: user.lastName,
      username: user.username,
      email: user.email,
      role: user.role,
    }));
  };

  function getAllUser() {
    var userRecord1 = GetAllPerson();
    userRecord1
      .then((data) => {
        if (data) {
          const transformedData = transformData(data)
          setEmployeeData(transformedData);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  function deleteUser() {
    var deleteUser = DeletePerson({ "id": deleteId });
    deleteUser
      .then((data) => {
        if (data) {
          console.log(data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }


  React.useEffect(() => {
    getAllUser();
  }, [])

  React.useEffect(() => {
    if (disableFields) {
      deleteUser();
      setDisableFields(false);
    }
  }, [disableFields])

  React.useEffect(() => {
    if (originalData) {
      setSearchValue([])
      setOriginalData(false);
    }
  }, [originalData]);

  if (filtername || filteremail) {
    var nameA = "";
    var nameB = "";
    filteredItems &&
      filteredItems.sort((a, b) => {
        if (filtername) {
          nameA = a?.name?.toUpperCase();
          nameB = b?.name?.toUpperCase();
        } else {
          nameA = a?.email?.toUpperCase();
          nameB = b?.email?.toUpperCase();
        }
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
  }

  const handleClosemenu = (operation) => {
    setUserVertragName(menuData.username)
    localStorage.setItem("awo-employee", menuData.username)
    localStorage.setItem("awo-employeeId", menuData.id)
    if (operation === Pages.CONTRACTS) {
      setCurrentComponent(operation);
    }
    else if (operation === "edit") {
      setOpen(true);
    }
    else if (operation === "delete") {
      setConfirmationModalOpen(true);
    }
    else if (operation === Pages.APPROVAL) {
      console.log("Approval Page" + operation);
      setCurrentComponent(operation);
    }
    setAnchorEl(null);
  };

  const handleClick = (event, data) => {
    setMenuData(data);
    setDeleteId(data.id)
    setAnchorEl(event.currentTarget);
    setEmployeeEditableData(data)
  };

  return (
    <>

      <div className={Style.mainDiv}>
        <div className={Style.div1}>
          <div className={Style.div2}>
            <button
              className={BoxStyle.AddBtn}
              onClick={() => {
                setEmployeeEditableData("")
                setOpen(true);
              }}
            >
              {t("add-user")}
            </button>
            <div className={Style.div3 + " z-50"}>
              <TokenizedSearchBar searchValue={searchValue} setSearchValue={setSearchValue} searchBtn={searchBtn} setSearchBtn={setSearchBtn} setFiltername={setFiltername} setOriginalData={setOriginalData} />
            </div>
          </div>
        </div>

        <PersonTable
          setFiltername={setFiltername}
          filtername={filtername}
          setFilteremail={setFilteremail}
          filteremail={filteremail}
          filteredItems={filteredItems}
          openmenu={openmenu}
          handleClick={handleClick}
          anchorEl={anchorEl}
          handleClosemenu={handleClosemenu}
        />
      </div>

      <AddPerson
        open={open}
        handleOpen={handleOpen}
        setOpen={setOpen}
        handleClose={handleClose}
        getAllUser={getAllUser}
        employeeEditableData={employeeEditableData}
        setEmployeeEditableData={setEmployeeEditableData}
      />

      <Confirmation
        disableFields={disableFields}
        setDisableFields={setDisableFields}
        confirmationModalOpen={confirmationModalOpen}
        setConfirmationModalOpen={setConfirmationModalOpen}
      />
    </>
  );
}

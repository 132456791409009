import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FetchContract } from "../../Api/Contract";
import ContractForm from "../AddContractForm/Contract";
import ContractCard from "./ContractCard";

export default function EmployeeContracts() {
  const { t } = useTranslation();
  const [contracts, setContracts] = useState([]);
  const [expanded, setExpanded] = useState({}); // State to track expanded contracts
  const [showAddContractForm, setShowAddContractForm] = useState(false);

  const fetchContracts = () => {
    const employeeID = localStorage.getItem("awo-employeeId");
    const params = `/api/employees/${employeeID}/contracts`;

    FetchContract(params)
      .then((data) => {
        console.log("fetching contracts.");
        console.log(data); // Log the API response
        const contractsArray = Object.values(data); // Convert the object to an array
        setContracts(contractsArray);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchContracts();
  }, []);

  const handleShowAddContractForm = () => {
    setShowAddContractForm(true);
  };

  const handleCancel = () => {
    setShowAddContractForm(false);
    fetchContracts(); // Refetch the contracts
  };

  const handleExpandClick = (contractNumber) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [contractNumber]: !prevExpanded[contractNumber],
    }));
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row", mt: "20px", ml: "10px" }}>
      {contracts.length > 0 && (
        <Box sx={{ flex: 1, mr: "20px", overflowY: "auto", maxHeight: "80vh" }}>
          {contracts.map((contract) => (
            <ContractCard
              key={contract.contractNumber}
              contract={contract}
              expanded={expanded}
              handleExpandClick={handleExpandClick}
            />
          ))}
        </Box>
      )}
      <Box sx={{ flex: 1 }}>
        {showAddContractForm ? (
          <ContractForm onCancel={handleCancel} />
        ) : (
          <Button variant="contained" color="primary" onClick={handleShowAddContractForm}>
            {t("add-contract")}
          </Button>
        )}
      </Box>
    </Box>
  );
}

import * as React from "react";
import { Modal, Typography, Button, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { confirmationStyle } from "../../../Person/Style/PersonModelStyle.js";
import DoneIcon from "@mui/icons-material/Done";
import { ToggleTheme } from "../../../../context/ToggleThemeProvider.js";
import { useTheme } from "../../../../context/UseThemeProvider.js";
import { useTranslation } from "react-i18next";

export default function Confirmation(props) {
    const { confirmationModalOpen, setDisableFields, setConfirmationModalOpen, disableFields, confirmSendData, title, content, confirm, cancel  } = props;
    var { currentTheme: theme } = useTheme();
    var { ThemeToggle } = ToggleTheme();
    const { t } = useTranslation();

    async function handleConfirm() {
        await confirmSendData();
        setConfirmationModalOpen(false);
        setDisableFields(true);
    }

    return (
        <>
            <Modal
                open={confirmationModalOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={confirmationStyle}
                    style={{
                        zIndex: 1,
                        minWidth: "100px",
                        maxHeight: "600px",
                        overflow: "scroll",
                        backgroundColor: (ThemeToggle === "dark" ? theme.palette.dullDark.main : "")
                    }}
                >
                    <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
                        <Typography variant="h5">
                            <Box
                                sx={{ fontWeight: 600, w: 100 }}
                                style={{
                                    color: ThemeToggle === "dark" ? "white" : "narrowWhite",
                                }}
                            >
                                {t(title)}
                            </Box>
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            {t(content)}
                        </Typography>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Button
                                sx={{ flex: 1, mr: 1 }}
                                endIcon={<DoneIcon />}
                                variant="contained"
                                style={{
                                    height: "35px",
                                    color: "white",
                                    textTransform: "capitalize",
                                    backgroundColor: "green",
                                }}
                                onClick={handleConfirm}
                            >
                                {t(confirm)}
                            </Button>
                            <Button
                                sx={{ flex: 1, ml: 1 }}
                                endIcon={<EditIcon />}
                                variant="contained"
                                style={{
                                    height: "35px",
                                    color: "white",
                                    textTransform: "capitalize",
                                    backgroundColor: theme.palette.blue.main
                                }}
                                onClick={() => {
                                    setConfirmationModalOpen(false);
                                }}
                            >
                                {t(cancel)}
                            </Button>
                        </Box>
                    </Box>
                </Box >
            </Modal >
        </>
    );
}

import { FetchTimes } from "../Api/POST/Times.js";
import { ConvertSecondsToTimeFormat } from "./TimeUtils.tsx";

export const fetchFilteredItems = async (currentYear, currentMonth, setFilteredItems, setDownloadItems, setDetailTime) => {
  // Fetch data for the whole month
  const params = `/api/me/times?from=${currentYear}-${currentMonth + 1}-01&to=${currentYear}-${currentMonth + 2}-01&mode=sum`;
  try {
    const data = await FetchTimes(params);
    if (data) {
      const items = Object.keys(data).map((key) => {
        const entry = data[key];
        const date = new Date(key);
        const options = { weekday: 'short', day: '2-digit', month: '2-digit', year: 'numeric' };
        const formattedDate = date.toLocaleDateString('de-de', options).replace(',', '');
        return {
          id: key,
          date: formattedDate,
          time: ConvertSecondsToTimeFormat(entry.total),
        };
      });
      setFilteredItems(items);
      setDownloadItems(items.map(({ id, date, time }) => ({ Datum: date, Dauer: time })));
    }
  } catch (error) {
    console.error(error);
  }

  // Fetch data for the whole day
  const params2 = `/api/me/times?from=${currentYear}-${currentMonth + 1}-01&to=${currentYear}-${currentMonth + 2}-01&mode=perday`;
  try {
    const data = await FetchTimes(params2);
    if (data) {
      setDetailTime(data);
    }
  } catch (error) {
    console.error(error);
  }
};

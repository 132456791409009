import React from "react";
import MonthSelectorComponent from "../MonthSelectorComponent";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import { Menu, Pages, } from '@mui/icons-material'
import InfoIcon from '@mui/icons-material/Info';
import NavPages from '../../Pages/Pages';
import BreadCrumb from "../BreadCrumb";

export function Header({
    drawerWidth,
    handleDrawerToggle,
    currentComponent,
    setCurrentComponent,
    setDetailModalOpen,
    userName,
}) {

    return (
        <AppBar position="fixed" elevation={0} sx={{
            width: {
                sm: `calc(100% - ${drawerWidth}px)`
            },
            height: {
                sm: "63px"
            },
            ml: {
                sm: `${drawerWidth}px`
            },
            backgroundColor: "secondary.dark",
            display: "flex",
            flexDirection: "row",
            justifyContent: "start"
        }}>
            <Toolbar>
                <IconButton aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{
                    mr: 2,
                    display: {
                        sm: "none"
                    }
                }}>
                    <Menu />
                </IconButton>
                <Typography variant="h5" sx={{
                    color: "white",
                    display: "flex",
                    ml: 0,
                    mt: 1,
                    width: "max-content"
                }}>
                    <BreadCrumb currentComponent={currentComponent} setCurrentComponent={setCurrentComponent} userName={userName} />
                    {/*
                    {currentComponent === NavPages.TIMEENTRY  && <InfoIcon className="cursor-pointer ml-2" onClick={() => { setDetailModalOpen(true) }} />}
                    */}
                </Typography>
            </Toolbar>
        </AppBar>
    )
}

const Pages = Object.freeze({
    HOME: "main-menu",
    PERSONS: "persons",
    GROUPS: "group",
    TIMEENTRY: "time-entry",
    EVALUATION: "evaluation",
    APPROVAL: "approval",
    CONTRACTS: "contracts",
    SETTINGS: "settings",
    LOGOUT: "logout",
});

export default Pages;
import React from "react";
import { Box, Card, CardContent, Typography, Divider, Collapse, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { getDaysOfWeek } from "../../utils/DateUtils"; // Import the utility function

export default function ContractCard({ contract, expanded, handleExpandClick }) {
  const { t } = useTranslation();
  const daysOfWeek = getDaysOfWeek(t); // Use the utility function

  return (
    <Card key={contract.contractNumber} sx={{ mb: 2, backgroundColor: "background.paper", boxShadow: 3 }}>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">
            {`${t("contract-number")}: ${contract.contractNumber}`}
          </Typography>
          <IconButton onClick={() => handleExpandClick(contract.contractNumber)}>
            {expanded[contract.contractNumber] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
        <Collapse in={expanded[contract.contractNumber]} timeout="auto" unmountOnExit>
          <Typography variant="body2">
            {`${t("start-date")}: ${dayjs(contract.entryTime).format("YYYY-MM-DD")} - ${t("end-date")}: ${contract.exitTime ? dayjs(contract.exitTime).format("YYYY-MM-DD") : t("unlimited")}`}
          </Typography>
          <Divider sx={{ my: 1 }} />
          <Typography variant="body2">
            {t("work-times")}:
          </Typography>
          {contract.workTimes.sort((a, b) => a.day - b.day).map((time, index) => (
            <Typography key={index} variant="body2">
              {`${daysOfWeek[time.day]}, ${t("start")}: ${dayjs().startOf('day').add(time.start, 'minute').format("HH:mm")}, ${t("end")}: ${dayjs().startOf('day').add(time.end, 'minute').format("HH:mm")}`}
            </Typography>
          ))}
          <Divider sx={{ my: 1 }} />
          <Typography variant="body2">
            {t("standby-times")}:
          </Typography>
          {contract.standbyTimes.sort((a, b) => a.day - b.day).map((time, index) => (
            <Typography key={index} variant="body2">
              {`${daysOfWeek[time.day]}, ${t("start")}: ${dayjs().startOf('day').add(time.start, 'minute').format("HH:mm")}, ${t("end")}: ${dayjs().startOf('day').add(time.end, 'minute').format("HH:mm")}`}
            </Typography>
          ))}
        </Collapse>
      </CardContent>
    </Card>
  );
}

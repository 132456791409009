import * as React from "react";
import { Header } from './Header';
import StartPage from "../../Pages/StartPage";
import Setting from "../../Pages/Settings";
import UserViewComponent from "../../Pages/Person/Component/UserView";
import Evaluation from '../../Pages/Evaluation';
import MyTimesPage from "../../Pages/MyTimesPage";
import TimeApproval from "../../Pages/TimeApproval";
import PropTypes from "prop-types";
import Pages from '../../Pages/Pages';
import Contracts from "../EmployeeContracts/EmployeeContracts";
import { useUser } from "../../context/UserProvider";
import { UserLogo } from "../../Images/DashboardImages";
import { useTheme } from "../../context/UseThemeProvider";
import { ToggleTheme } from "../../context/ToggleThemeProvider";
import { SidebarDrawer } from './SidebarDrawer';
import { FetchAccessibleAreas } from "../../Api/GET/AccessibleAreas"; // Import the new API function
import { Box, Container, CssBaseline, GlobalStyles, ThemeProvider, Toolbar } from "@mui/material";
import { CalendarMonthOutlined, HomeOutlined, AccessTimeOutlined} from "@mui/icons-material";

const drawerWidth = 270;

function RootNav() {
  const window = null;
  const { user } = useUser();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [currentDate, setCurrentDate] = React.useState(new Date());
  const [currentMonth, setCurrentMonth] = React.useState(new Date().getMonth());
  const [check, setCheck] = React.useState(true);
  const [userName, setUserName] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [detailModalOpen, setDetailModalOpen] = React.useState(false);
  const container = window !== undefined ? () => document.body : undefined;
  const [accessibleAreas, setAccessibleAreas] = React.useState([]); // New state variable
  var months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const [currentYear, setCurrentYear] = React.useState(
    new Date().getFullYear()
  );

  const [currentComponent, setCurrentComponent] = React.useState(null); // Initialize as null

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };
  
  // Dynamically create ListMenu based on accessibleAreas
  var ListMenu = Array.isArray(accessibleAreas["ui-areas"]) ? accessibleAreas["ui-areas"].map(area => Pages[area.toUpperCase()]) : [];

  var ListIcons = {
    [Pages.HOME]: <HomeOutlined sx={{ color: "white" }}/>,
    [Pages.PERSONS]: <UserLogo />,
    [Pages.TIMEENTRY]: <AccessTimeOutlined sx={{ color: "white" }} />,
    [Pages.EVALUATION]: <CalendarMonthOutlined sx={{ color: "white" }} />,
    [Pages.APPROVAL]: <AccessTimeOutlined sx={{ color: "white" }} />, // Added icon for Pages.APPROVAL
  };

  const UserDetailProps = { currentMonth, setCurrentMonth, currentYear, setCurrentYear, open, setOpen, months, currentDate, setCurrentDate, detailModalOpen, setDetailModalOpen }
  const UserDetail2Props = { currentMonth, setCurrentMonth, currentYear, setCurrentYear, open, setOpen, months, currentDate, setCurrentDate, setCurrentComponent }
  const HeaderProps = { 
    drawerWidth, 
    detailModalOpen, 
    setDetailModalOpen, 
    handleDrawerToggle, 
    userName, 
    currentComponent, 
    setCurrentComponent 
  };
  const TimeApprovalProps = { setCurrentComponent, currentMonth, setCurrentMonth, currentYear, setCurrentYear, open, setOpen, months, currentDate, setCurrentDate }

  const navAreas = [
      Pages.TIMEENTRY,
      Pages.EVALUATION,
      Pages.PERSONS,
      Pages.GROUPS,
  ]; // List of possible areas

  const SidebarDrawerProps = { 
    drawerWidth, 
    container, 
    mobileOpen, 
    handleDrawerTransitionEnd, 
    handleDrawerClose, 
    setCurrentComponent: (component) => {
      setCurrentComponent(component);
      localStorage.setItem("CurrentComponent", component);
    }, 
    setIsClosing, 
    setMobileOpen, 
    ListIcons, 
    ListMenu,
    navAreas, // Pass possible areas
    accessibleAreas // Pass accessible areas
  };

  const UserViewProps = { currentComponent, setCurrentComponent: (component) => {
    setCurrentComponent(component);
    localStorage.setItem("CurrentComponent", component);
  }, setUserVertragName: setUserName }
  
  React.useEffect(() => {
    // Fetch accessible areas on component mount
    FetchAccessibleAreas()
      .then((data) => {
        setAccessibleAreas(data);
        const storedComponent = localStorage.getItem("CurrentComponent");
        const storedComponentKey = Object.keys(Pages).find(key => Pages[key] === storedComponent);
        if (!data["ui-areas"].includes(storedComponentKey)) {
          const firstAccessibleArea = data["ui-areas"][0];
          setCurrentComponent(Pages[firstAccessibleArea.toUpperCase()]);
          localStorage.setItem("CurrentComponent", Pages[firstAccessibleArea.toUpperCase()]);
        } else {
          setCurrentComponent(Pages[storedComponentKey]);
        }
      })
      .catch((error) => {
        console.error('Error fetching accessible areas:', error);
      });
  }, []); // Empty dependency array ensures this runs only once on mount

  var setComponent;

  switch (currentComponent) {
    default:
      break;

    case Pages.HOME:
      console.log("Rendering Home component");
      setComponent =  <StartPage />;
      break;

    case Pages.PERSONS:
      console.log("Rendering UserViewComponent");
      setComponent =  <UserViewComponent {...UserViewProps} />;
      break;

    case Pages.APPROVAL:
      console.log("Rendering TimeApproval component");
      setComponent =  <TimeApproval {...TimeApprovalProps} />;
      break;

    case Pages.TIMEENTRY:
      console.log("Rendering MyTimesPage component");
      setComponent =  <MyTimesPage {...UserDetailProps} />;
      break;

    case Pages.EVALUATION:
      console.log("Rendering UserDetail2 component");
      setComponent =  <Evaluation {...UserDetail2Props} />;
      break;

    case Pages.CONTRACTS:
      console.log("Rendering Contracts component");
      setComponent =  <Contracts />;
      break;

    case Pages.SETTINGS:
      console.log("Rendering Setting component");
      setComponent =  <Setting />;
      break;

  }

  // Add useEffect to update component when month selection changes
  React.useEffect(() => {
    // Logic to update the component based on currentMonth and currentYear changes
    console.log("Month or Year changed:", currentMonth, currentYear);
    // You can add any additional logic here if needed
  }, [currentMonth, currentYear]);

  var { ThemeToggle } = ToggleTheme();
  var { currentTheme: theme } = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth={false}
        sx={{
          paddingInline: "0px",
        }}
      >
        <Box sx={{ display: "flex", width: "100%" }}>
          <CssBaseline />
          <Header {...HeaderProps} />
          <SidebarDrawer {...SidebarDrawerProps} />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
          >
            <Toolbar />
            <GlobalStyles
              styles={{
                body: { backgroundColor: theme.palette.background.secondary },
              }}
            />
            {setComponent} {/* Render the selected component here */}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

RootNav.propTypes = {
  window: PropTypes.func,
};

export default RootNav;
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import MuiAccordion from '@mui/material/Accordion';
import TableContainer from '@mui/material/TableContainer';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { WorkOutline as WorkIcon, FreeBreakfastOutlined as BreakIcon, ExitToApp as OutIcon, Alarm as StandbyIcon } from "@mui/icons-material";
import { useTheme } from '../context/UseThemeProvider';
import { CalculateDuration, ConvertSecondsToTimeFormat, FormatDate } from '../utils/TimeUtils.tsx';
import { extractTimeFromTimestamp } from '../utils/ExtractTime';
import { useTranslation } from 'react-i18next';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper': {
        transform: 'rotate(0deg)',
        transition: 'transform 0.3s',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));


const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const cellWidth_default = "80px";
const cellWidth_time = "70px";
const cellWidth_date = "150px";

export default function EmployeeTimesPage(props) {
    const [selectedItems, setSelectedItems] = React.useState([]);
    const [dateLists, setDateLists] = React.useState({});
    const { currentTheme: theme } = useTheme();
    const { editable, filteredItem, detailTime } = props;
    const { t } = useTranslation();

    function getScheduleForDate(date) {
        const formattedDate = date.toISOString().split("T")[0];

        if (!dateLists[formattedDate]) {
            const schedule = [];

            if (detailTime?.hasOwnProperty(formattedDate)) {
                const entries = detailTime[formattedDate];
                for (const entry of entries) {
                    if (entry.type !== "required-break") {
                        const startTime = new Date(entry.start.date);
                        const endTime = new Date(startTime.getTime() + entry.duration * 1000);
                        schedule.push({
                            type: entry.type,
                            source_id: entry.source_id,
                            start: entry.start.date,
                            end: endTime.toISOString(),
                            isStart: entry.isStart,
                            isEnd: entry.isEnd,
                        });
                    } else {
                        schedule.push({
                            type: entry.type,
                            duration: entry.duration,
                        });

                    }

                    setDateLists(prevState => ({
                        ...prevState,
                        [formattedDate]: schedule
                    }));
                }
            }
        }
    }

    const handleIconClick = (event, item) => {
        event.stopPropagation();
        setSelectedItems((prevSelected) => {
            if (prevSelected.find((selected) => selected.date === item.date)) {
                return prevSelected.filter((selected) => selected.date !== item.date);
            } else {
                return [...prevSelected, item];
            }
        });
    };

    const isSelected = (item) => {
        return selectedItems.find((selected) => selected.date === item.date) !== undefined;
    };

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 500 }}>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ width: "50px", minWidth: "50px", maxWidth: "50px" }} />
                        <TableCell sx={{ width: cellWidth_date, minWidth: cellWidth_date, maxWidth: cellWidth_date }}>{t("date")}</TableCell>
                        <TableCell sx={{ width: cellWidth_time, minWidth: cellWidth_time, maxWidth: cellWidth_time }}>{t("from")}</TableCell>
                        <TableCell sx={{ width: cellWidth_time, minWidth: cellWidth_time, maxWidth: cellWidth_time }}>{t("until")}</TableCell>
                        <TableCell sx={{ minWidth: cellWidth_default }}>{t("duration")}</TableCell>
                        <TableCell sx={{ width: cellWidth_default, minWidth: cellWidth_default, maxWidth: cellWidth_default }}>{t("status")}</TableCell>
                        <TableCell sx={{ width: "50px", minWidth: "50px", maxWidth: "50px" }}>{editable && t("action")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredItem && filteredItem.length > 0 && filteredItem.map((item, index) => (
                        <React.Fragment key={index}>
                            <TableRow>
                                <TableCell colSpan={8} sx={{ p: 0 }}>
                                    <Accordion>
                                        <AccordionSummary
                                            id={`panel${index}-header`}
                                            sx={{ backgroundColor: theme.palette.secondary.main, color: "white", p: 0, pl: "15px" }}
                                            expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                                            onClick={() => { getScheduleForDate(new Date(item.id)) }}
                                        >
                                            <Table size="small" aria-label={`details for ${item.date}`}>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell sx={{ color: "white", width: cellWidth_date, minWidth: cellWidth_date, maxWidth: cellWidth_date }}>{FormatDate(item.date)}</TableCell>
                                                        <TableCell sx={{ color: "white", width: cellWidth_time, minWidth: cellWidth_time, maxWidth: cellWidth_time }} />
                                                        <TableCell sx={{ color: "white", width: cellWidth_time, minWidth: cellWidth_time, maxWidth: cellWidth_time }} />
                                                        <TableCell sx={{ minWidth: cellWidth_default, color: "white" }}>{item.time}</TableCell>
                                                        <TableCell sx={{ width: cellWidth_default, minWidth: cellWidth_default, maxWidth: cellWidth_default, color: "white" }}>{t("open")}</TableCell>
                                                        <TableCell sx={{ width: "50px", minWidth: "50px", maxWidth: "50px" }} onClick={(event) => handleIconClick(event, item)}>
                                                            {editable && (
                                                                <CheckCircleOutlineIcon
                                                                    sx={{ color: isSelected(item) ? theme.palette.green.main : 'gray' }}
                                                                />
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ padding: 0 }}>
                                            <Table size="small" aria-label={`details for ${item.date}`}>
                                                <TableBody>
                                                    {dateLists[item.date]?.map((entry, entryIndex) => (
                                                        <TableRow key={entryIndex}>
                                                            <TableCell sx={{ width: "48px", maxWidth: "48px" }}>
                                                                {
                                                                    entry.type === 'work' ? (<WorkIcon sx={{ marginLeft: 0 }} />) :
                                                                        entry.type === 'break' ? (<BreakIcon />) :
                                                                            entry.type === 'required-break' ? (<BreakIcon />) :
                                                                                entry.type === 'standby' ? (<StandbyIcon />) :
                                                                                    entry.type === 'sick' ? (<OutIcon />) :
                                                                                        entry.type === 'vacation' ? (<OutIcon />) : null
                                                                }
                                                            </TableCell>
                                                            <TableCell sx={{ width: cellWidth_date, minWidth: cellWidth_date, maxWidth: cellWidth_date, color: "gray" }}>{FormatDate(item.date)}</TableCell>
                                                            {
                                                                entry.type !== "required-break" ? 
                                                                (
                                                                    <>
                                                                        <TableCell sx={{ width: cellWidth_time, minWidth: cellWidth_time, maxWidth: cellWidth_time }}>{extractTimeFromTimestamp(entry.start)}</TableCell>
                                                                        <TableCell sx={{ width: cellWidth_time, minWidth: cellWidth_time, maxWidth: cellWidth_time }}>{extractTimeFromTimestamp(entry.end)}</TableCell>
                                                                    </>
                                                                ) :
                                                                (
                                                                    <>
                                                                        <TableCell sx={{ width: cellWidth_time, minWidth: cellWidth_time, maxWidth: cellWidth_time }} colSpan={2}>{t("required")}</TableCell>
                                                                    </>
                                                                )
                                                            }
                                                            <TableCell sx={{ minWidth: cellWidth_default }}>
                                                                { 
                                                                entry.type === "required-break" ? 
                                                                    ConvertSecondsToTimeFormat(entry.duration) : CalculateDuration(entry.start, entry.end)
                                                                }
                                                            </TableCell>
                                                            <TableCell sx={{ width: cellWidth_default, minWidth: cellWidth_default, maxWidth: cellWidth_default }}>{t("open")}</TableCell>
                                                            <TableCell sx={{ width: "50px", minWidth: "50px", maxWidth: "50px" }} onClick={(event) => handleIconClick(event, item)}>
                                                                {editable && (
                                                                    <CheckCircleOutlineIcon
                                                                        sx={{ color: isSelected(item) ? theme.palette.green.main : 'gray' }}
                                                                    />
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </AccordionDetails>
                                    </Accordion>
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

import * as React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';

export default function StartPage() {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
      <h1 style={{ color: theme.palette.text.primary, fontSize: '2.5em', fontFamily: 'Arial, sans-serif' }}>{t('welcome_message')}</h1>
    </div>
  );
}

import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Pages from '../Pages/Pages';
import { useTranslation } from 'react-i18next';

export default function BreadCrumb(props) {
    const { currentComponent, setCurrentComponent, userName } = props
    const { t } = useTranslation()

    var setHeaderTitle = (
        <>
            {
                t("menu-item-label." + currentComponent)
            }
        </>
    );

    const breadcrumbs = [
        <Link underline="none" key={2} color="white" className='cursor-pointer' >
            {setHeaderTitle}
        </Link>
    ];

    const userCrumbs = [
        <Link underline="none" key={0} color="white" className='cursor-pointer' onClick={() => { setCurrentComponent(Pages.PERSONS) }}>
            {t("menu-item-label." + Pages.PERSONS)}
        </Link>,
        <Link underline="none" key={1} color="white" className='cursor-pointer' onClick={() => { setCurrentComponent(Pages.PERSONS) }}>
            {userName}
        </Link>
    ];
    
    React.useEffect(() => {
        // if contract page is open but person is selected, Navigate to Person page
        if (currentComponent === Pages.CONSTRACTS && !userName) {
            setCurrentComponent(Pages.PERSONS)
        }
    }, [currentComponent, userName])


    return (
        <Stack spacing={2}>
            <Breadcrumbs separator="›" aria-label="breadcrumb" fontSize="large" sx={{ color: 'dull.main' }}>
                {(currentComponent === Pages.CONSTRACTS || currentComponent === Pages.APPROVAL) && userCrumbs}
                {breadcrumbs}
            </Breadcrumbs>
        </Stack>
    );
}

import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      primary: "#000000",
      secondary: "#333333",
      tertiary: "#555555",
    },
    primary: {
      main: "#272727",
      light: "#333333",
      dark: "#272727",
    },
    secondary: {
      main: "#5E5E5E",
      light: "#5E5E5E",
      dark: "#393939",
    },
    common: {
      dark: "#ffffff",
    },
    transparent: {
      main: "#ffffff00",
    },
    warning: {
      main: "#9B2F33",
    },
    border: {
      main: "#ffffff",
    },
    error: {
      main: "#9B2F33",
    },
    accent: {
      main: "#E30613",
      light: "#E30613",
      dark: "#CA0101",
      dull: "#393939",
      toolbar: "#ffffff",
    },
    blue: {
      main: "#3255B0",
    },
    dullBlack: {
      main: "#ffffff",
    },
    dullPink: {
      main: "#EBC8C2"
    },
    dullDark: {
      main: "#363636"
    },
    green: {
      main: "#339933"
    },
    brownDark: {
      main: "#5E5E5E"
    },
    dullGray: {
      main: "#E0DCDC"
    },
    narrowWhite: {
      main: "#DADADA",
    },
    dark: {
      main: "#5E5E5E",
    },
    dull: {
      main: "#A6A6A6"
    },
    narrowDull: {
      main: "#808080"
    }
  },
});

export default theme;

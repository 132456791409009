const daysOfWeek = [
  { value: 0, label: "sunday" },
  { value: 1, label: "monday" },
  { value: 2, label: "tuesday" },
  { value: 3, label: "wednesday" },
  { value: 4, label: "thursday" },
  { value: 5, label: "friday" },
  { value: 6, label: "saturday" },
];

export function getDaysOfWeek(t) {
  return daysOfWeek.map(day => t(day.label));
}

import { api } from "../../utils/axiosInstance";
import { getAuthHeaders } from "../../utils/axiosInstance";

const FetchAccessibleAreas = async () => {
    const response = await api.get(
        `${process.env.REACT_APP_API_URL}/api/me/ui-areas`,
        getAuthHeaders()
    );
    return response.data;
};

export {
    FetchAccessibleAreas,
};
import * as React from "react";
import TimesTable from "./UserDetail/Component/UserModal/TimesPerDayTable.jsx";
import Style from "./Styles/MyTimesPageStyles.js";
import { ToggleTheme } from "../context/ToggleThemeProvider.js";
import { FetchTimes } from "../Api/POST/Times.js";
import ErrorBoundary from "../utils/ErrorBoundary.jsx";
import TimeEntryForm from "../Components/AddTimeEntryForm/Form.jsx";
import MonthSelectorComponent from "../Components/MonthSelectorComponent.jsx";
import { ConvertSecondsToTimeFormat } from "../utils/TimeUtils.tsx";
import dayjs from "dayjs";
import { fetchFilteredItems } from "../utils/fetchFilteredItems.js";

export default function MyTimesPage(props) {
  const {
    currentMonth, setCurrentMonth, currentYear, setCurrentYear, open, setOpen, months, currentDate, setCurrentDate, detailModalOpen, setDetailModalOpen
  } = props;

  const [userTimes, setUserTimes] = React.useState();
  const [filteredItems, setFilteredItems] = React.useState([]);
  const [downloadItems, setDownloadItems] = React.useState([]);
  const [detailTime, setDetailTime] = React.useState([]);
  const [minDate, setMinDate] = React.useState(dayjs("2024-01-01"));
  const [successModal, setSuccessModal] = React.useState(false);
  const jwtToken = localStorage.getItem("jwt");
 
  const MonthSelectorProps = {
    currentMonth, setCurrentMonth, currentYear, setCurrentYear, open,
    setOpen, months, setCurrentDate, minDate
  };

  const userTableProps = {
    filteredItems, setFilteredItems, downloadItems, setDownloadItems, currentMonth, currentYear, months, detailTime, setDetailTime, detailModalOpen, setDetailModalOpen
  };

  const fetchUserTimes = () => {
    fetchFilteredItems(currentYear, currentMonth, setFilteredItems, setDownloadItems, setDetailTime);
  };

  React.useEffect(() => {
    fetchUserTimes();
  }, [successModal, jwtToken, currentYear, currentMonth]);

  const handleFormSubmit = () => {
    fetchUserTimes();
  };

  React.useEffect(() => {
    if (userTimes) {
      const items = Object.keys(userTimes).map(key => ({
        id: key,
        date: key,
        time: ConvertSecondsToTimeFormat(userTimes[key].total),
      }));
      setFilteredItems(items);
      setDownloadItems(items.map(({ id, date, time }) => ({ Datum: date, Dauer: time })));
    }
  }, [userTimes]);

  const { ThemeToggle } = ToggleTheme();

  return (
    <>
      <div className={ThemeToggle === "dark" ? "bg-browndark" : ""}>
        <ErrorBoundary>
          <TimeEntryForm onFormSubmit={handleFormSubmit} />
        </ErrorBoundary>
      </div>
      <div className={Style.div5}>
        <div className="w-[100%]">
          <ErrorBoundary>
            <div className={"bg-dullBlack justify-center flex w-[100%] whitespace-nowrap"}>
              <MonthSelectorComponent {...MonthSelectorProps} />
            </div>
            <TimesTable {...userTableProps} />
          </ErrorBoundary>
        </div>
      </div>
    </>
  );
}

import * as React from "react";
import Pages from '../../Pages/Pages';
import LogoutIcon from "@mui/icons-material/Logout";
import { useUser } from "../../context/UserProvider";
import SettingsIcon from '@mui/icons-material/Settings';
import { SidebarHeader } from "../../Images/DashboardImages";
import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function SidebarDrawerItems({ onItemClick, ListIcons, ListMenu, navAreas = [] }) { // Add default value for navAreas
  const { user } = useUser();
  const { t } = useTranslation();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          mt: 1,
          mb: 1,
          ml: 0,
        }}
      >
        <SidebarHeader />
      </Box>
      
      <Divider sx={{ bgcolor: "secondary.light" }} />
      
      {/* Sidebar menu items */}   
      <List>
        {ListMenu.filter(key => navAreas.includes(key)).map( // Use navAreas instead of pages
            //Sidebar menu option based on condition
            (key) =>
            (
              <ListItem key={key} disablePadding>
              <ListItemButton
                onClick={() => {
                onItemClick(key);
                }}>
                <ListItemIcon>{ListIcons[key]}</ListItemIcon>
                <ListItemText
                primaryTypographyProps={{ fontSize: "19px" }}
                sx={{ ml: -1 }}
                primary={t("menu-item-label."+key)}
                />
              </ListItemButton>
              </ListItem>
            )
          )}
          <Divider sx={{ bgcolor: "secondary.light" }} />

          {/* settings */}
        <ListItem key={Pages.SETTINGS} disablePadding>
          <ListItemButton
            onClick={() => {
              onItemClick(Pages.SETTINGS);
            }}>
            <ListItemIcon><SettingsIcon sx={{ color: "white" }} /></ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ fontSize: "19px" }}
              sx={{ ml: -1 }}
              primary={t("menu-item-label.settings")}
            />
          </ListItemButton>
        </ListItem>
        
        {/* logout */}
        <ListItem
          key={Pages.LOGOUT}
          style={{ position: "fixed", bottom: "0px", width: "270px" }}
          disablePadding
        >
          <ListItemButton
            onClick={() => {
              onItemClick(Pages.LOGOUT);
            }}
          >
            <ListItemIcon>
              <LogoutIcon sx={{ color: "common.dark" }} />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ fontSize: "19px" }}
              sx={{ ml: -1 }}
              primary={t("menu-item-label.logout")}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </>
  );
}

import Style from "./Styles/EvaluationStyles.js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FetchTimes } from "../Api/Detail2.js";
import DownloadIcon from '@mui/icons-material/Download';
import CsvDownloader from "../Components/CsvDownloader.jsx";
import { ToggleTheme } from "../context/ToggleThemeProvider.js";
import * as React from "react";
import MonthSelectorComponent from "../Components/MonthSelectorComponent.jsx";
import dayjs from "dayjs";

function Evaluation(props) {
    const { t } = useTranslation();
    const { currentMonth, setCurrentMonth, currentYear, setCurrentYear, open, setOpen, months, setCurrentDate } = props
    var { ThemeToggle } = ToggleTheme();
    var colStyle = Style.td + " md:pl-4 py-6";
    const [downloadItems, setDownloadItems] = useState(null);
    const [detailTime, setDetailTime] = useState([]);
    const [minDate, setMinDate] = useState(dayjs('2024-01-01'));
       
    const MonthSelectorProps = {
        currentMonth, setCurrentMonth, currentYear, setCurrentYear, open,
        setOpen, months, setCurrentDate, minDate
      };

    function convertSecondsToTimeFormat(totalSeconds) {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(minutes).padStart(2, "0");

        return `${formattedHours}:${formattedMinutes}`;
    }

    useEffect(() => {
        console.log("Getting data for the month.");
        var params2 = `/api/me/times?from=${currentYear}-${currentMonth + 1
            }-01&to=${currentYear}-${currentMonth + 2}-01&mode=sum`;

        var employeeData = FetchTimes(params2);
        employeeData
            .then((data) => {
                if (data) {
                    setDownloadItems(
                        Object.keys(data)?.map((key) => {
                            const entry = data[key];
                            // Api Data To Add Here to show in Table and in download
                            return {
                                'Tag': key,
                                'Samstag 13:00- 21:00': entry.sat,
                                'Sonntag 00:00- 24:00': entry.sun,
                                'Nacht 21:00- 06:00': entry.night,
                                'Dienstzeit': convertSecondsToTimeFormat(entry.total),
                            };
                        })
                    );
                }
            })
            .catch((error) => {
            });

        setDownloadItems(
            detailTime?.map(entry => ({
                'Tag': entry['Tag'],
                'Soll': entry['Soll'],
                'Dienstzeit': entry['Dienstzeit'],
                'Arb-Std': entry['Arb-Std'],
                'Samstag 13:00- 21:00': entry['Samstag 13:00- 21:00'],
                'Sonntag 00:00- 24:00': entry['Sonntag 00:00- 24:00'],
                'Nacht 21:00- 06:00': entry['Nacht 21:00- 06:00'],
                'Auszahlung Mehra': entry['Auszahlung Mehra'],
                'Urlab/Tagen': entry['Urlab/Tagen'],
                'Krankheit/Tagen': entry['Krankheit/Tagen'],
                'KA in Std': entry['KA in Std']
            }))
        )
    }, [currentMonth, currentYear])



    return (
        <>
            <div className={"bg-dullBlack justify-center flex w-[100%] whitespace-nowrap"}>
                <MonthSelectorComponent
                    {...MonthSelectorProps}
                />
            </div>
            <div className="h-fit">
                <button className="mt-8 md:mt-0" style={{ color: ThemeToggle === "dark" ? "white" : "black", padding: 5, fontWeight: 900 }} onClick={() => {
                    CsvDownloader(downloadItems, (months[currentMonth]) + " " + currentYear);
                }}> {t("download")} <DownloadIcon />
                </button>
                <table
                    className={Style.table + (ThemeToggle === "dark" ? " bg-gray" : "")}
                    style={{ height: "calc(100%-250px)]" }}
                >
                    <thead>
                        <tr>
                            <th className={Style.th}>{t("day")}</th>
                            <th className={Style.th}>{t("should")}</th>
                            <th className={Style.th + "pl-0"}>{t("day-hours")}</th>
                            <th className={Style.th}>{t("working-hours")}</th>
                            <th className={Style.th}>{t("saturday-night")}</th>
                            <th className={Style.th}>{t("sunday-day")}</th>
                            <th className={Style.th}>{t("night")}</th>
                            <th className={Style.th}>{t("payout")}</th>
                            <th className={Style.th}>{t("vacation")}</th>
                            <th className={Style.th}>{t("sick-days")}</th>
                            <th className={Style.th}>{t("ka-hours")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {downloadItems?.map((item, index) => (
                            <tr key={index} className={index % 2 === 0 ? "bg-narrow" : "bg-textBox"}>
                                <td className={Style.td + Style.tag}>
                                    {item['Tag'] ?? 0}
                                </td>
                                <td className={colStyle}>
                                    {item['Soll'] ?? 0}
                                </td>
                                <td className={colStyle}>
                                    {item['Dienstzeit'] ?? 0}
                                </td>
                                <td className={colStyle}>
                                    {item['Arb-Std'] ?? 0}
                                </td>
                                <td className={colStyle}>
                                    {item['Samstag 13:00- 21:00'] ?? 0}
                                </td>
                                <td className={colStyle}>
                                    {item['Sonntag 00:00- 24:00'] ?? 0}
                                </td>
                                <td className={colStyle}>
                                    {item['Nacht 21:00- 06:00'] ?? 0}
                                </td>
                                <td className={colStyle}>
                                    {item['Auszahlung Mehra'] ?? 0}
                                </td>
                                <td className={colStyle}>
                                    {item['Urlab/Tagen'] ?? 0}
                                </td>
                                <td className={colStyle}>
                                    {item['Krankheit/Tagen'] ?? 0}
                                </td>
                                <td className={colStyle}>
                                    {item['KA in Std'] ?? 0}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {
                    downloadItems?.length === 0 && (
                        <div className={Style.noRecord}>
                            {t("no-recordFound")}
                        </div>
                    )
                }
            </div>
        </>
    );
}

export default Evaluation;
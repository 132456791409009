import React, { useState, useEffect } from 'react';
import { Box, InputLabel, Typography, Select, MenuItem } from "@mui/material";
import { ToggleTheme } from "../context/ToggleThemeProvider.js";
import { useTheme } from '../context/UseThemeProvider.js';
import { useTranslation } from 'react-i18next';
import i18n from "../utils/translation/i18n.js";

const Setting = () => {
    const { t } = useTranslation();
    var { currentTheme } = useTheme();
    var { ThemeToggle, setThemeToggle } = ToggleTheme();
    const [theme, setTheme] = useState(ThemeToggle || "light"); // Ensure valid initial value
    const [language, setLanguage] = useState(i18n.resolvedLanguage);

    const handleLanguageChange = (event) => {
        setLanguage(event.target.value);
    };

    const handleThemeChange = (event) => {
        setTheme(event.target.value);
    };

    useEffect(() => {
        i18n.changeLanguage(language);
        setThemeToggle(theme);
        localStorage.setItem("ThemeColor", theme);
    }, [language, theme, setThemeToggle]);

    return (
        <>
            <Typography variant="h6" sx={{ mt: "20px", mb: "10px" }}>
                {t("appearance")}
            </Typography>
            <Box sx={{ display: "flex", mt: "10px"}}>
                <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                        color: currentTheme.palette.text.primary,
                        mt: "8px",
                        width: "100px"
                    }}
                >
                    {t("theme")}
                </InputLabel>
                <Select
                    value={theme}
                    onChange={handleThemeChange}
                    sx={{
                        height: "40px",
                        boxShadow: 1,
                        minWidth: "200px"
                    }}
                >
                    <MenuItem value={"light"}>{t("light")}</MenuItem>
                    <MenuItem value={"dark"}>{t("dark")}</MenuItem>
                </Select>
            </Box>
            <Box sx={{ display: "flex", mt: "10px"}}>
                <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                        color: currentTheme.palette.text.primary,
                        mt: "8px",
                        width: "100px"
                    }}
                >
                    {t("language")}
                </InputLabel>
                <Select
                    value={language}
                    onChange={handleLanguageChange}
                    sx={{
                        height: "40px",
                        width: "200px",
                        boxShadow: 1,
                        minWidth: "200px"
                    }}
                >
                    <MenuItem value={"gb"}>{t("english")}</MenuItem>
                    <MenuItem value={"de"}>{t("german")}</MenuItem>
                </Select>
            </Box>
        </>
    );
};

export default Setting;
